import React from 'react';
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <div className="bg-white dark:bg-gray-800 overflow-hidden shadow-xl sm:rounded-lg p-6">
      <Helmet>
        <title>Contact Us - Multitoolapp.com</title>
        <meta
          name="description"
          content="We'd love to hear from you! Contact Multitoolapp.com for suggestions, feedback, or just to say hello."
        />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200">Contact Us</h2>
      <p className="text-gray-700 dark:text-gray-300">
        We'd love to hear from you! Whether you have a suggestion for a new 
        tool, feedback on our existing products, or just want to say hello, 
        please don't hesitate to reach out.
      </p>
      <p className="mt-4 text-gray-700 dark:text-gray-300">
        Have questions, suggestions, or feedback? Reach out to us at <a href="mailto:multitoolapp0@gmail.com" className="text-primary dark:text-primary hover:underline">multitoolapp0@gmail.com</a>.
      </p>
    </div>
  );
};

export default Contact;