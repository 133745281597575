import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CopyButton from './CopyButton';

const Base64EncoderDecoder = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [mode, setMode] = useState('encode');

  const handleEncodeDecode = () => {
    if (mode === 'encode') {
      setOutput(btoa(input));
    } else {
      try {
        setOutput(atob(input));
      } catch (error) {
        setOutput('Invalid Base64 input');
      }
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <Helmet>
        <title>Base64 Encoder/Decoder - Multitoolapp.com</title>
        <meta
          name="description"
          content="Use our Base64 Encoder/Decoder to quickly encode or decode text. A handy tool for developers and users alike."
        />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200">Base64 Encoder/Decoder</h2>
      <div className="mb-4 text-gray-700 dark:text-gray-300">
        <p>Encode or decode text using Base64 encoding.</p>
        <p className="text-sm mt-2">To use: Enter your text, choose 'Encode' or 'Decode', then click the button to perform the operation. Switch between modes using the 'Switch to' button.</p>
      </div>
      <div className="mb-4">
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={mode === 'encode' ? 'Enter text to encode' : 'Enter Base64 to decode'}
          className="w-full px-3 py-2 text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
          rows="4"
        />
      </div>
      <div className="flex space-x-4 mb-4">
        <button
          onClick={handleEncodeDecode}
          className="flex-1 bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark transition-colors duration-150"
        >
          {mode === 'encode' ? 'Encode' : 'Decode'}
        </button>
        <button
          onClick={() => setMode(mode === 'encode' ? 'decode' : 'encode')}
          className="flex-1 bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-gray-200 px-4 py-2 rounded-md hover:bg-gray-400 dark:hover:bg-gray-500 transition-colors duration-150"
        >
          Switch to {mode === 'encode' ? 'Decode' : 'Encode'}
        </button>
      </div>
      <div className="bg-white dark:bg-gray-700 p-4 rounded-md shadow-sm flex items-center">
        <p className="flex-1 text-gray-900 dark:text-gray-200 break-all">{output}</p>
        {output && <CopyButton textToCopy={output} />}
      </div>
    </div>
  );
};

export default Base64EncoderDecoder;