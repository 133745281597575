import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const utilities = [
  { path: '/countdown', name: 'Countdown Timer', description: 'Set and track countdown timers for various events.' },
  { path: '/color-picker', name: 'Color Picker', description: 'Select and explore different colors with our intuitive color picker.' },
  { path: '/random-number', name: 'Random Number Generator', description: 'Generate random numbers within a specified range.' },
  { path: '/base64', name: 'Base64 Encoder/Decoder', description: 'Encode or decode text using Base64 encoding.' },
  { path: '/text-case', name: 'Text Case Converter', description: 'Convert text between different cases (e.g., lowercase, uppercase, title case).' },
  { path: '/calculator', name: 'Simple Calculator', description: 'Perform basic arithmetic calculations quickly and easily.' },
  { path: '/unit-converter', name: 'Unit Converter', description: 'Convert between different units of measurement.' },
  { path: '/password-generator', name: 'Password Generator', description: 'Create strong, secure passwords for your accounts.' },
  { path: '/qrcode-generator', name: 'QRCode Generator', description: 'Generate QR codes for various types of data.' },
  { path: '/date-difference', name: 'Date Difference Calculator', description: 'Calculate the difference between two dates.' },
  { path: '/timezone-converter', name: 'Timezone Converter', description: 'Convert times between different time zones.' },
  { path: '/speech-to-text', name: 'Speech to Text', description: 'Convert spoken words into written text.' },
];

const Home = () => {
  const widgetRefs = useRef([]);

  useEffect(() => {
    const resizeWidgets = () => {
      let maxWidth = 0;
      let maxHeight = 0;

      widgetRefs.current.forEach((widget) => {
        const rect = widget.getBoundingClientRect();
        maxWidth = Math.max(maxWidth, rect.width);
        maxHeight = Math.max(maxHeight, rect.height);
      });

      widgetRefs.current.forEach((widget) => {
        widget.style.width = `${maxWidth}px`;
        widget.style.height = `${maxHeight}px`;
      });
    };

    resizeWidgets();
    window.addEventListener('resize', resizeWidgets);

    return () => {
      window.removeEventListener('resize', resizeWidgets);
    };
  }, []);

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <Helmet>
        <title>Multitoolapp - All-in-one Utility Site</title>
        <meta
          name="description"
          content="Multitoolapp.com offers a variety of online tools like QR Code Generator, Date Difference Calculator, Timezone Converter, and more to make your life easier."
        />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200">Welcome to Multitoolapp.com!</h2>
      <p className="mb-6 text-gray-700 dark:text-gray-300">Your one-stop destination for a variety of online tools. Whether you need to encode text, generate random numbers, or pick a color, we have you covered.</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {utilities.map(({ path, name, description }, index) => (
          <Link
            key={path}
            to={path}
            ref={(el) => (widgetRefs.current[index] = el)}
            className="block p-4 rounded-md bg-blue-100 dark:bg-blue-900 hover:bg-blue-200 dark:hover:bg-blue-700 transition-colors duration-150 flex flex-col"
          >
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-200 mb-2">{name}</h3>
            <p className="text-sm text-gray-700 dark:text-gray-300 flex-grow">{description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Home;