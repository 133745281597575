import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CountdownTimer from './components/CountdownTimer';
import ColorPicker from './components/ColorPicker';
import RandomNumberGenerator from './components/RandomNumberGenerator';
import Base64EncoderDecoder from './components/Base64EncoderDecoder';
import TextCaseConverter from './components/TextCaseConverter';
import SimpleCalculator from './components/SimpleCalculator';
import UnitConverter from './components/UnitConverter';
import PasswordGenerator from './components/PasswordGenerator';
import QRCodeGenerator from './components/QRCodeGenerator'; 
import DateDifferenceCalculator from './components/DateDifferenceCalculator'; 
import TimezoneConverter from './components/TimezoneConverter'; 
import About from './components/About';
import Contact from './components/Contact';
import SupportUs from './components/SupportUs';
import DarkModeToggle from './components/DarkModeToggle';
import Home from './components/Home';
import SpeechToText from './components/SpeechToText';
import './index.css';

const utilities = [
  { path: '/countdown', name: 'Countdown Timer', component: CountdownTimer },
  { path: '/color-picker', name: 'Color Picker', component: ColorPicker },
  { path: '/random-number', name: 'Random Number Generator', component: RandomNumberGenerator },
  { path: '/base64', name: 'Base64 Encoder/Decoder', component: Base64EncoderDecoder },
  { path: '/text-case', name: 'Text Case Converter', component: TextCaseConverter },
  { path: '/calculator', name: 'Simple Calculator', component: SimpleCalculator },
  { path: '/unit-converter', name: 'Unit Converter', component: UnitConverter },
  { path: '/password-generator', name: 'Password Generator', component: PasswordGenerator },
  { path: '/qrcode-generator', name: 'QRCode Generator', component: QRCodeGenerator },
  { path: '/date-difference', name: 'Date Difference Calculator', component: DateDifferenceCalculator },
  { path: '/timezone-converter', name: 'Timezone Converter', component: TimezoneConverter },
  { path: '/speech-to-text', name: 'Speech to Text', component: SpeechToText },
];

const UtilityButtons = () => {
  const location = useLocation();
  
  if (location.pathname === '/') {
    return null;
  }

  return (
    <div className="flex flex-wrap gap-4 mb-8">
      {utilities.map(({ path, name }) => (
        <Link
          key={path}
          to={path}
          className="px-4 py-2 rounded-md text-sm font-medium transition-colors duration-150 bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
        >
          {name}
        </Link>
      ))}
    </div>
  );
};

const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Router>
      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800">
        <nav className="bg-white dark:bg-gray-900 shadow-sm">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <h1 className="text-2xl font-bold text-primary dark:text-white">Multitoolapp.com</h1>
                </div>
                <div className="hidden md:flex items-center ml-10 space-x-4">
                  <Link to="/" className="text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Home</Link>
                  <Link to="/about" className="text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">About</Link>
                  <Link to="/contact" className="text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Contact</Link>
                  <Link to="/support" className="text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Support Us</Link>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <DarkModeToggle />
              </div>
              <div className="-mr-2 flex md:hidden">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:bg-gray-100 dark:focus:bg-gray-700 focus:text-gray-500"
                  aria-controls="mobile-menu"
                  aria-expanded={isOpen}
                >
                  <span className="sr-only">Open main menu</span>
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={!isOpen ? "M4 6h16M4 12h16m-7 6h7" : "M6 18L18 6M6 6l12 12"} />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className={`${isOpen ? 'block' : 'hidden'} md:hidden`} id="mobile-menu">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Link to="/" className="text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">Home</Link>
              <Link to="/about" className="text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">About</Link>
              <Link to="/contact" className="text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">Contact</Link>
              <Link to="/support" className="text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">Support Us</Link>
            </div>
          </div>
        </nav>

        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <UtilityButtons />
            <Routes>
              <Route path="/" element={<Home />} />
              {utilities.map(({ path, component: Component }) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/support" element={<SupportUs />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;