import React, { useState, useEffect } from 'react';
import { ArrowRightLeft } from 'lucide-react';
import CopyButton from './CopyButton';
import { Helmet } from 'react-helmet';

const unitTypes = {
  length: {
    m: { label: 'Meters', conversions: { km: 0.001, mi: 0.000621371, ft: 3.28084 } },
    km: { label: 'Kilometers', conversions: { m: 1000, mi: 0.621371, ft: 3280.84 } },
    mi: { label: 'Miles', conversions: { m: 1609.34, km: 1.60934, ft: 5280 } },
    ft: { label: 'Feet', conversions: { m: 0.3048, km: 0.0003048, mi: 0.000189394 } }
  },
  weight: {
    kg: { label: 'Kilograms', conversions: { g: 1000, lb: 2.20462, oz: 35.274 } },
    g: { label: 'Grams', conversions: { kg: 0.001, lb: 0.00220462, oz: 0.035274 } },
    lb: { label: 'Pounds', conversions: { kg: 0.453592, g: 453.592, oz: 16 } },
    oz: { label: 'Ounces', conversions: { kg: 0.0283495, g: 28.3495, lb: 0.0625 } }
  },
  volume: {
    l: { label: 'Liters', conversions: { ml: 1000, gal: 0.264172, oz: 33.814 } },
    ml: { label: 'Milliliters', conversions: { l: 0.001, gal: 0.000264172, oz: 0.033814 } },
    gal: { label: 'Gallons', conversions: { l: 3.78541, ml: 3785.41, oz: 128 } },
    oz: { label: 'Ounces', conversions: { l: 0.0295735, ml: 29.5735, gal: 0.0078125 } }
  }
};

const UnitConverter = () => {
  const [unitType, setUnitType] = useState('length');
  const [inputValue, setInputValue] = useState(0);
  const [inputUnit, setInputUnit] = useState('m');
  const [outputValue, setOutputValue] = useState(0);
  const [outputUnit, setOutputUnit] = useState('km');

  useEffect(() => {
    const convert = () => {
      if (inputUnit === outputUnit) {
        setOutputValue(inputValue);
        return;
      }

      const inputUnitData = unitTypes[unitType]?.[inputUnit];
      const conversionFactor = inputUnitData?.conversions?.[outputUnit];

      if (conversionFactor !== undefined) {
        const result = inputValue * conversionFactor;
        setOutputValue(result % 1 === 0 ? result : result.toFixed(6));
      } else {
        setOutputValue(0);
        console.error('Invalid conversion:', unitType, inputUnit, outputUnit);
      }
    };

    convert();
  }, [inputValue, inputUnit, outputUnit, unitType]);

  const handleSwapUnits = () => {
    setInputUnit(outputUnit);
    setOutputUnit(inputUnit);
    setInputValue(outputValue);
  };

  const handleUnitTypeChange = (e) => {
    const newUnitType = e.target.value;
    setUnitType(newUnitType);
    const newUnits = Object.keys(unitTypes[newUnitType]);
    setInputUnit(newUnits[0]);
    setOutputUnit(newUnits[1] || newUnits[0]);
  };

  return (
    <div className="max-w-md mx-auto bg-gradient-to-br from-teal-400 to-blue-500 p-8 rounded-2xl shadow-lg">
      <Helmet>
        <title>Unit Converter - Multitoolapp.com</title>
        <meta
          name="description"
          content="Convert units of length, weight, and volume with our Unit Converter tool. Easy-to-use interface for quick conversions."
        />
      </Helmet>
      <h2 className="text-3xl font-bold mb-6 text-white text-center">Unit Converter</h2>
      
      <div className="mb-4">
        <label className="block text-white text-sm font-bold mb-2">Select Unit Type:</label>
        <select 
          value={unitType} 
          onChange={handleUnitTypeChange}
          className="w-full px-4 py-3 text-gray-800 bg-white bg-opacity-20 backdrop-filter backdrop-blur-md border border-white border-opacity-30 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent transition-all duration-300"
        >
          <option value="length">Length</option>
          <option value="weight">Weight</option>
          <option value="volume">Volume</option>
        </select>
      </div>
      
      <div className="mb-6 space-y-4">
        <div className="flex items-center space-x-4">
          <input
            type="number"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="w-2/3 px-4 py-3 text-gray-800 bg-white bg-opacity-20 backdrop-filter backdrop-blur-md border border-white border-opacity-30 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent transition-all duration-300"
          />
          <select 
            value={inputUnit} 
            onChange={(e) => setInputUnit(e.target.value)}
            className="w-36 px-4 py-3 text-white bg-white bg-opacity-20 backdrop-filter backdrop-blur-md border border-white border-opacity-30 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent transition-all duration-300"
          >
            {Object.keys(unitTypes[unitType]).map((unit) => (
              <option key={unit} value={unit}>
                {unitTypes[unitType][unit].label}
              </option>
            ))}
          </select>
        </div>
        
        <div className="flex justify-center">
          <button 
            onClick={handleSwapUnits}
            className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-md text-white p-2 rounded-full hover:bg-opacity-30 transition-all duration-300"
          >
            <ArrowRightLeft size={24} />
          </button>
        </div>
        
        <div className="flex items-center space-x-4">
          <input 
            type="number" 
            value={outputValue} 
            readOnly 
            className="w-2/3 px-4 py-3 text-gray-800 bg-white bg-opacity-20 backdrop-filter backdrop-blur-md border border-white border-opacity-30 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent transition-all duration-300"
          />
          <select 
            value={outputUnit} 
            onChange={(e) => setOutputUnit(e.target.value)}
            className="w-36 px-4 py-3 text-white bg-white bg-opacity-20 backdrop-filter backdrop-blur-md border border-white border-opacity-30 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent transition-all duration-300"
          >
            {Object.keys(unitTypes[unitType]).map((unit) => (
              <option key={unit} value={unit}>
                {unitTypes[unitType][unit].label}
              </option>
            ))}
          </select>
        </div>
      </div>
      
      <div className="bg-white p-4 rounded-md shadow-sm flex items-center">
        <p className="flex-1 text-gray-800 break-all">{outputValue}</p>
        <CopyButton textToCopy={outputValue.toString()} />
      </div>
    </div>
  );
};

export default UnitConverter;