import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CopyButton from './CopyButton'; // Import the CopyButton component

const TextCaseConverter = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');

  const convertCase = (type) => {
    switch (type) {
      case 'upper':
        setOutputText(inputText.toUpperCase());
        break;
      case 'lower':
        setOutputText(inputText.toLowerCase());
        break;
      case 'title':
        setOutputText(
          inputText.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
        );
        break;
      case 'camel':
        setOutputText(
          inputText.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
        );
        break;
      case 'snake':
        setOutputText(inputText.toLowerCase().replace(/\s+/g, '_'));
        break;
      default:
        setOutputText(inputText);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <Helmet>
        <title>Text Case Converter - Multitoolapp.com</title>
        <meta
          name="description"
          content="Easily convert text to different cases with our Text Case Converter tool. Supports UPPERCASE, lowercase, Title Case, camelCase, and snake_case."
        />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200">Text Case Converter</h2>
      <div className="mb-4">
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Enter text to convert"
          className="w-full px-3 py-2 text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
          rows="4"
        />
      </div>
      <div className="grid grid-cols-2 gap-2 mb-4">
        <button onClick={() => convertCase('upper')} className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark transition-colors duration-150">UPPERCASE</button>
        <button onClick={() => convertCase('lower')} className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark transition-colors duration-150">lowercase</button>
        <button onClick={() => convertCase('title')} className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark transition-colors duration-150">Title Case</button>
        <button onClick={() => convertCase('camel')} className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark transition-colors duration-150">camelCase</button>
        <button onClick={() => convertCase('snake')} className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark transition-colors duration-150">snake_case</button>
      </div>
      <div className="bg-white dark:bg-gray-700 p-4 rounded-md shadow-sm flex items-center">
        <p className="flex-1 text-gray-900 dark:text-gray-200 break-all">{outputText}</p>
        {outputText && <CopyButton textToCopy={outputText} />}
      </div>
    </div>
  );
};

export default TextCaseConverter;