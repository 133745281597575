import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CopyButton from './CopyButton'; // Import the new CopyButton component

const RandomNumberGenerator = () => {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(100);
  const [randomNumber, setRandomNumber] = useState(null);

  const generateRandomNumber = () => {
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    setRandomNumber(randomNum);
  };

  return (
    <div className="max-w-md mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <Helmet>
        <title>Random Number Generator - Multitoolapp.com</title>
        <meta
          name="description"
          content="Generate random numbers easily with our Random Number Generator tool. Set your min and max values to customize the range."
        />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200">Random Number Generator</h2>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Min:</label>
          <input
            type="number"
            value={min}
            onChange={(e) => setMin(parseInt(e.target.value))}
            className="w-full px-3 py-2 text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Max:</label>
          <input
            type="number"
            value={max}
            onChange={(e) => setMax(parseInt(e.target.value))}
            className="w-full px-3 py-2 text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
      </div>
      <button
        onClick={generateRandomNumber}
        className="w-full bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark transition-colors duration-150"
      >
        Generate
      </button>
      {randomNumber !== null && (
        <div className="mt-4 p-4 bg-white dark:bg-gray-700 rounded-md shadow-sm flex items-center">
          <p className="flex-1 text-3xl font-bold text-gray-900 dark:text-gray-200">{randomNumber}</p>
          <CopyButton textToCopy={randomNumber.toString()} />
        </div>
      )}
    </div>
  );
};

export default RandomNumberGenerator;