import React, { useState, useRef } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { saveAs } from 'file-saver';
import { toPng, toJpeg, toSvg } from 'html-to-image';
import { jsPDF } from 'jspdf';

const QRCodeGenerator = () => {
  const [url, setUrl] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const qrRef = useRef(null);

  const validateUrl = (input) => {
    const urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    return urlRegex.test(input);
  };

  const handleUrlChange = (e) => {
    const input = e.target.value;
    setUrl(input);
    setIsValidUrl(validateUrl(input));
  };

  const generateFileName = (extension) => {
    return `qr_${url.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.${extension}`;
  };

  const downloadAs = async (format) => {
    if (!qrRef.current || !isValidUrl) return;

    setIsGenerating(true);
    try {
      let blob;
      switch (format) {
        case 'png':
          blob = await toPng(qrRef.current);
          break;
        case 'jpeg':
          blob = await toJpeg(qrRef.current);
          break;
        case 'svg':
          blob = await toSvg(qrRef.current);
          break;
        case 'pdf':
          const pdf = new jsPDF();
          const imgData = await toPng(qrRef.current);
          pdf.addImage(imgData, 'PNG', 10, 10, 90, 90);
          blob = pdf.output('blob');
          break;
        default:
          throw new Error('Unsupported format');
      }
      saveAs(blob, generateFileName(format));
    } catch (error) {
      console.error('Error generating file:', error);
      alert('An error occurred while generating the file. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  const buttonStyles = {
    svg: 'bg-blue-500 hover:bg-blue-600 active:bg-blue-700',
    png: 'bg-blue-600 hover:bg-blue-700 active:bg-blue-800',
    jpeg: 'bg-blue-700 hover:bg-blue-800 active:bg-blue-900',
    pdf: 'bg-blue-800 hover:bg-blue-900 active:bg-blue-950',
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200">QR Code Generator</h2>
      <input
        type="text"
        value={url}
        onChange={handleUrlChange}
        placeholder="Enter a valid URL"
        className="w-full px-3 py-2 text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-700 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-primary"
        maxLength={200}
      />
      <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
        Enter a valid URL to generate a QR code
      </p>
      <div className="flex justify-center mb-4" ref={qrRef}>
        {isValidUrl ? (
          <QRCode value={url} size={200} />
        ) : (
          <div className="w-48 h-48 border-2 border-dashed border-gray-300 dark:border-gray-600 flex items-center justify-center">
            <span className="text-gray-400 dark:text-gray-500">QR Code Preview</span>
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 gap-3">
        {['SVG', 'PNG', 'JPEG', 'PDF'].map((format) => (
          <button
            key={format}
            onClick={() => downloadAs(format.toLowerCase())}
            disabled={!isValidUrl || isGenerating}
            className={`py-2 px-4 rounded-md text-white font-semibold shadow-md transition duration-300 ${
              isValidUrl && !isGenerating
                ? buttonStyles[format.toLowerCase()]
                : 'bg-gray-300 dark:bg-gray-700 cursor-not-allowed'
            }`}
          >
            Download {format}
          </button>
        ))}
      </div>
      {isGenerating && (
        <p className="text-sm text-gray-600 dark:text-gray-400 mt-2 text-center">Generating file...</p>
      )}
    </div>
  );
};

export default QRCodeGenerator;