import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Play, Pause, RotateCcw, XCircle } from 'lucide-react';

const CountdownTimer = () => {
  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(false);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [playSound, setPlaySound] = useState(false);

  useEffect(() => {
    let interval;
    if (running && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0 && running) {
      setRunning(false);
      if (playSound) {
        playBeep();
      }
    }
    return () => clearInterval(interval);
  }, [running, time, playSound]);

  const handleStart = () => {
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    if (totalSeconds > 0) {
      setTime(totalSeconds);
      setRunning(true);
    }
  };

  const handleStop = () => {
    setRunning(false);
  };

  const handleReset = () => {
    setRunning(false);
    setTime(0);
    setHours(0);
    setMinutes(0);
    setSeconds(0);
  };

  const handleClear = () => {
    setHours(0);
    setMinutes(0);
    setSeconds(0);
    setTime(0);
    setRunning(false);
  };

  const playBeep = () => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = audioContext.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(440, audioContext.currentTime); // A4 note
    oscillator.connect(audioContext.destination);
    oscillator.start();
    setTimeout(() => {
      oscillator.stop();
    }, 1000); // Beep for 1 second
  };

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="max-w-md mx-auto bg-gradient-to-br from-purple-600 to-blue-500 p-8 rounded-2xl shadow-lg">
      <Helmet>
        <title>Countdown Timer - Multitoolapp.com</title>
        <meta
          name="description"
          content="Use our Countdown Timer to track time for various tasks and activities. Simple and easy to use."
        />
      </Helmet>
      <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-gray-200 text-center">Countdown Timer</h2>
      <div className="mb-6 flex justify-between">
        <div className="flex flex-col items-center">
          <label className="text-gray-900 dark:text-gray-200 mb-2">Hours</label>
          <input
            type="number"
            value={hours}
            onChange={(e) => setHours(parseInt(e.target.value))}
            disabled={running}
            className="w-full px-4 py-3 text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-800 bg-opacity-20 backdrop-filter backdrop-blur-md border border-white border-opacity-30 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent transition-all duration-300 text-center placeholder-gray-400"
          />
        </div>
        <div className="flex flex-col items-center">
          <label className="text-gray-900 dark:text-gray-200 mb-2">Minutes</label>
          <input
            type="number"
            value={minutes}
            onChange={(e) => setMinutes(parseInt(e.target.value))}
            disabled={running}
            className="w-full px-4 py-3 text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-800 bg-opacity-20 backdrop-filter backdrop-blur-md border border-white border-opacity-30 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent transition-all duration-300 text-center placeholder-gray-400"
          />
        </div>
        <div className="flex flex-col items-center">
          <label className="text-gray-900 dark:text-gray-200 mb-2">Seconds</label>
          <input
            type="number"
            value={seconds}
            onChange={(e) => setSeconds(parseInt(e.target.value))}
            disabled={running}
            className="w-full px-4 py-3 text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-800 bg-opacity-20 backdrop-filter backdrop-blur-md border border-white border-opacity-30 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent transition-all duration-300 text-center placeholder-gray-400"
          />
        </div>
      </div>
      <div className="flex items-center mb-4">
        <label className="text-gray-900 dark:text-gray-200 mr-2">Play sound when done:</label>
        <input
          type="checkbox"
          checked={playSound}
          onChange={(e) => setPlaySound(e.target.checked)}
          className="w-4 h-4 text-primary form-checkbox"
        />
      </div>
      <div className="flex justify-center space-x-4 mb-6">
        <button
          onClick={running ? handleStop : handleStart}
          className="bg-white dark:bg-gray-800 bg-opacity-20 backdrop-filter backdrop-blur-md text-gray-900 dark:text-gray-200 px-6 py-3 rounded-full hover:bg-opacity-30 transition-all duration-300 flex items-center justify-center w-16 h-16"
        >
          {running ? <Pause size={24} /> : <Play size={24} />}
        </button>
        <button
          onClick={handleReset}
          className="bg-white dark:bg-gray-800 bg-opacity-20 backdrop-filter backdrop-blur-md text-gray-900 dark:text-gray-200 px-6 py-3 rounded-full hover:bg-opacity-30 transition-all duration-300 flex items-center justify-center w-16 h-16"
        >
          <RotateCcw size={24} />
        </button>
        <button
          onClick={handleClear}
          className="bg-white dark:bg-gray-800 bg-opacity-20 backdrop-filter backdrop-blur-md text-gray-900 dark:text-gray-200 px-6 py-3 rounded-full hover:bg-opacity-30 transition-all duration-300 flex items-center justify-center w-16 h-16"
        >
          <XCircle size={24} />
        </button>
      </div>
      <div className="text-6xl font-bold text-center text-gray-900 dark:text-gray-200 mb-4 transition-all duration-300 ease-in-out transform hover:scale-110">
        {formatTime(time)}
      </div>
      <div className="w-full bg-white dark:bg-gray-800 bg-opacity-20 rounded-full h-2 mb-4">
        <div 
          className="bg-white dark:bg-gray-200 h-2 rounded-full transition-all duration-300 ease-linear"
          style={{ width: `${(time / (hours * 3600 + minutes * 60 + seconds || 1)) * 100}%` }}
        ></div>
      </div>
    </div>
  );
};

export default CountdownTimer;