import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
import CopyButton from './CopyButton';

const timezones = [
  { label: 'UTC-12:00 (Baker Island Time)', value: 'Etc/GMT+12' },
  { label: 'UTC-11:00 (Niue Time)', value: 'Etc/GMT+11' },
  { label: 'UTC-10:00 (Hawaii-Aleutian Standard Time)', value: 'Etc/GMT+10' },
  { label: 'UTC-09:30 (Marquesas Time)', value: 'Pacific/Marquesas' },
  { label: 'UTC-09:00 (Alaska Standard Time)', value: 'Etc/GMT+9' },
  { label: 'UTC-08:00 (Pacific Standard Time)', value: 'Etc/GMT+8' },
  { label: 'UTC-07:00 (Mountain Standard Time)', value: 'Etc/GMT+7' },
  { label: 'UTC-06:00 (Central Standard Time)', value: 'Etc/GMT+6' },
  { label: 'UTC-05:00 (Eastern Standard Time)', value: 'Etc/GMT+5' },
  { label: 'UTC-04:00 (Atlantic Standard Time)', value: 'Etc/GMT+4' },
  { label: 'UTC-03:30 (Newfoundland Standard Time)', value: 'America/St_Johns' },
  { label: 'UTC-03:00 (Argentina Time)', value: 'Etc/GMT+3' },
  { label: 'UTC-02:00 (South Georgia Time)', value: 'Etc/GMT+2' },
  { label: 'UTC-01:00 (Azores Standard Time)', value: 'Etc/GMT+1' },
  { label: 'UTC±00:00 (Coordinated Universal Time)', value: 'Etc/UTC' },
  { label: 'UTC+01:00 (Central European Time)', value: 'Etc/GMT-1' },
  { label: 'UTC+02:00 (Eastern European Time)', value: 'Etc/GMT-2' },
  { label: 'UTC+03:00 (Moscow Standard Time)', value: 'Etc/GMT-3' },
  { label: 'UTC+03:30 (Iran Standard Time)', value: 'Asia/Tehran' },
  { label: 'UTC+04:00 (Gulf Standard Time)', value: 'Etc/GMT-4' },
  { label: 'UTC+04:30 (Afghanistan Time)', value: 'Asia/Kabul' },
  { label: 'UTC+05:00 (Pakistan Standard Time)', value: 'Etc/GMT-5' },
  { label: 'UTC+05:30 (India Standard Time)', value: 'Asia/Kolkata' },
  { label: 'UTC+05:45 (Nepal Time)', value: 'Asia/Kathmandu' },
  { label: 'UTC+06:00 (Bangladesh Standard Time)', value: 'Etc/GMT-6' },
  { label: 'UTC+06:30 (Cocos Islands Time)', value: 'Indian/Cocos' },
  { label: 'UTC+07:00 (Indochina Time)', value: 'Etc/GMT-7' },
  { label: 'UTC+08:00 (China Standard Time)', value: 'Etc/GMT-8' },
  { label: 'UTC+08:30 (North Korea Time)', value: 'Asia/Pyongyang' },
  { label: 'UTC+09:00 (Japan Standard Time)', value: 'Etc/GMT-9' },
  { label: 'UTC+09:30 (Australian Central Standard Time)', value: 'Australia/Adelaide' },
  { label: 'UTC+10:00 (Australian Eastern Standard Time)', value: 'Etc/GMT-10' },
  { label: 'UTC+10:30 (Lord Howe Standard Time)', value: 'Australia/Lord_Howe' },
  { label: 'UTC+11:00 (Solomon Islands Time)', value: 'Etc/GMT-11' },
  { label: 'UTC+12:00 (Fiji Time)', value: 'Etc/GMT-12' },
  { label: 'UTC+12:45 (Chatham Islands Time)', value: 'Pacific/Chatham' },
  { label: 'UTC+13:00 (Phoenix Islands Time)', value: 'Pacific/Enderbury' },
  { label: 'UTC+14:00 (Line Islands Time)', value: 'Pacific/Kiritimati' },
];

const TimezoneConverter = () => {
  const [time, setTime] = useState('');
  const [fromTimezone, setFromTimezone] = useState('');
  const [toTimezone, setToTimezone] = useState('');
  const [convertedTime, setConvertedTime] = useState('');
  const [error, setError] = useState('');

  const setCurrentTime = () => {
    setTime(moment().format('YYYY-MM-DDTHH:mm'));
  };

  const convertTime = () => {
    if (!time || !fromTimezone || !toTimezone) {
      setError('Please fill out all fields.');
      return;
    }

    setError('');
    const converted = moment.tz(time, fromTimezone).tz(toTimezone).format('YYYY-MM-DD HH:mm:ss');
    setConvertedTime(converted);
  };

  return (
    <div className="max-w-md mx-auto bg-white dark:bg-gray-800 p-8 rounded-lg shadow-md">
      <Helmet>
        <title>Timezone Converter - Multitoolapp.com</title>
        <meta
          name="description"
          content="Convert time between different time zones with our Timezone Converter tool. Easy-to-use interface for quick conversions."
        />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200">Timezone Converter</h2>
      <div className="flex mb-4">
        <input
          type="datetime-local"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          className="flex-grow p-2 text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-700 rounded-l-md"
        />
        <button
          onClick={setCurrentTime}
          className="py-2 px-4 bg-blue-500 text-white rounded-r-md hover:bg-blue-600 transition-all duration-300 ease-in-out"
        >
          Now
        </button>
      </div>
      <select
        value={fromTimezone}
        onChange={(e) => setFromTimezone(e.target.value)}
        className="w-full p-2 text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-700 rounded mb-4"
      >
        <option value="">Select From Timezone</option>
        {timezones.map((tz) => (
          <option key={tz.value} value={tz.value}>
            {tz.label}
          </option>
        ))}
      </select>
      <select
        value={toTimezone}
        onChange={(e) => setToTimezone(e.target.value)}
        className="w-full p-2 text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-700 rounded mb-4"
      >
        <option value="">Select To Timezone</option>
        {timezones.map((tz) => (
          <option key={tz.value} value={tz.value}>
            {tz.label}
          </option>
        ))}
      </select>
      <button
        onClick={convertTime}
        className="w-full py-2 px-4 rounded bg-blue-500 text-white cursor-pointer hover:bg-blue-600 transition-all duration-300 ease-in-out"
      >
        Convert Time
      </button>
      {error && (
        <p className="mt-4 text-red-500">
          {error}
        </p>
      )}
      {convertedTime && (
        <div className="mt-4 text-lg flex items-center text-gray-900 dark:text-gray-200">
          <p>Converted Time: {convertedTime}</p>
          <CopyButton textToCopy={convertedTime} />
        </div>
      )}
    </div>
  );
};

export default TimezoneConverter;