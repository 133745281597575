import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const DateDifferenceCalculator = () => {
  const [date1, setDate1] = useState('');
  const [date2, setDate2] = useState('');
  const [difference, setDifference] = useState(null);
  const [error, setError] = useState('');

  const calculateDifference = () => {
    if (!date1 || !date2) {
      setError('Please select both dates.');
      setDifference(null);
      return;
    }

    const parsedDate1 = new Date(date1);
    const parsedDate2 = new Date(date2);

    if (isNaN(parsedDate1.getTime()) || isNaN(parsedDate2.getTime())) {
      setError('Invalid date format. Please use the date picker.');
      setDifference(null);
      return;
    }

    setError('');
    const diffInTime = Math.abs(parsedDate2 - parsedDate1);
    const diffInDays = Math.round(diffInTime / (1000 * 3600 * 24));
    setDifference(diffInDays);
  };

  return (
    <div className="max-w-md mx-auto bg-white dark:bg-gray-800 p-8 rounded-lg shadow-md">
      <Helmet>
        <title>Date Difference Calculator - Multitoolapp.com</title>
        <meta
          name="description"
          content="Calculate the difference in days between two dates with our easy-to-use Date Difference Calculator."
        />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200">Date Difference Calculator</h2>
      <div className="relative mb-4">
        <input
          type="date"
          value={date1}
          onChange={(e) => setDate1(e.target.value)}
          className="w-full p-2 text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-700 rounded cursor-pointer"
          onFocus={(e) => e.target.showPicker()}
        />
      </div>
      <div className="relative mb-4">
        <input
          type="date"
          value={date2}
          onChange={(e) => setDate2(e.target.value)}
          className="w-full p-2 text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-700 rounded cursor-pointer"
          onFocus={(e) => e.target.showPicker()}
        />
      </div>
      <button
        onClick={calculateDifference}
        className="w-full py-2 px-4 rounded bg-blue-500 hover:bg-blue-600 transition-all duration-300 ease-in-out text-white"
      >
        Calculate Difference
      </button>
      {error && (
        <p className="mt-4 text-red-500">
          {error}
        </p>
      )}
      {difference !== null && !error && (
        <p className="mt-4 text-lg text-gray-900 dark:text-gray-200">
          The difference is {difference} day{difference !== 1 ? 's' : ''}.
        </p>
      )}
    </div>
  );
};

export default DateDifferenceCalculator;