import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CopyButton from './CopyButton';

const SimpleCalculator = () => {
  const [display, setDisplay] = useState('0');
  const [firstOperand, setFirstOperand] = useState(null);
  const [operator, setOperator] = useState(null);
  const [waitingForSecondOperand, setWaitingForSecondOperand] = useState(false);
  const [lastButtonPressed, setLastButtonPressed] = useState(null);

  const inputDigit = (digit) => {
    if (waitingForSecondOperand) {
      setDisplay(String(digit));
      setWaitingForSecondOperand(false);
    } else {
      setDisplay(display === '0' ? String(digit) : display + digit);
    }
    setLastButtonPressed(digit);
  };

  const inputDecimal = () => {
    if (waitingForSecondOperand) {
      setDisplay('0.');
      setWaitingForSecondOperand(false);
      return;
    }
    if (!display.includes('.')) {
      setDisplay(display + '.');
    }
    setLastButtonPressed('.');
  };

  const clear = () => {
    setDisplay('0');
    setFirstOperand(null);
    setOperator(null);
    setWaitingForSecondOperand(false);
    setLastButtonPressed(null);
  };

  const performOperation = (nextOperator) => {
    const inputValue = parseFloat(display);
    if (firstOperand === null) {
      setFirstOperand(inputValue);
    } else if (operator) {
      const result = calculate(firstOperand, inputValue, operator);
      setDisplay(String(result));
      setFirstOperand(result);
    }
    setWaitingForSecondOperand(true);
    setOperator(nextOperator);
    setLastButtonPressed(nextOperator);
  };

  const calculate = (firstOperand, secondOperand, operator) => {
    switch (operator) {
      case '+':
        return firstOperand + secondOperand;
      case '-':
        return firstOperand - secondOperand;
      case '*':
        return firstOperand * secondOperand;
      case '/':
        return firstOperand / secondOperand;
      default:
        return secondOperand;
    }
  };

  const handleKeyPress = (event) => {
    const { key } = event;
    if (/\d/.test(key)) {
      inputDigit(parseInt(key, 10));
    } else if (key === '.') {
      inputDecimal();
    } else if (key === 'Enter' || key === '=') {
      performOperation('=');
    } else if (['+', '-', '*', '/'].includes(key)) {
      performOperation(key);
    } else if (key === 'Backspace') {
      clear();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [display, waitingForSecondOperand, firstOperand, operator]);

  return (
    <div className="max-w-md mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <Helmet>
        <title>Simple Calculator - Multitoolapp.com</title>
        <meta
          name="description"
          content="Perform basic arithmetic operations with our Simple Calculator tool. Easy-to-use interface with keyboard support."
        />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200">Simple Calculator</h2>
      <div className="bg-white dark:bg-gray-700 p-4 rounded-md shadow-sm">
        <div className="flex items-center mb-4 bg-gray-100 dark:bg-gray-600 p-2 rounded-md">
          <div className="flex-grow">
            <p className="text-right text-2xl font-semibold text-gray-900 dark:text-gray-200">{display}</p>
          </div>
          <CopyButton textToCopy={display} />
        </div>
        <div className="grid grid-cols-3 gap-2">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((digit) => (
            <button
              key={digit}
              onClick={() => inputDigit(digit)}
              className={`bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500 text-gray-900 dark:text-gray-200 font-semibold py-2 px-4 rounded-md transition-colors duration-150 ${lastButtonPressed === digit ? 'border-2 border-yellow-500' : ''}`}
            >
              {digit}
            </button>
          ))}
          <button onClick={clear} className={`bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md transition-colors duration-150 ${lastButtonPressed === 'C' ? 'border-2 border-yellow-500' : ''}`}>C</button>
          <button onClick={() => inputDigit(0)} className={`bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500 text-gray-900 dark:text-gray-200 font-semibold py-2 px-4 rounded-md transition-colors duration-150 ${lastButtonPressed === 0 ? 'border-2 border-yellow-500' : ''}`}>0</button>
          <button onClick={inputDecimal} className={`bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500 text-gray-900 dark:text-gray-200 font-semibold py-2 px-4 rounded-md transition-colors duration-150 ${lastButtonPressed === '.' ? 'border-2 border-yellow-500' : ''}`}>.</button>
          <button onClick={() => performOperation('+')} className={`bg-primary hover:bg-primary-dark text-white font-semibold py-2 px-4 rounded-md transition-colors duration-150 ${lastButtonPressed === '+' ? 'border-2 border-yellow-500' : ''}`}>+</button>
          <button onClick={() => performOperation('-')} className={`bg-primary hover:bg-primary-dark text-white font-semibold py-2 px-4 rounded-md transition-colors duration-150 ${lastButtonPressed === '-' ? 'border-2 border-yellow-500' : ''}`}>-</button>
          <button onClick={() => performOperation('*')} className={`bg-primary hover:bg-primary-dark text-white font-semibold py-2 px-4 rounded-md transition-colors duration-150 ${lastButtonPressed === '*' ? 'border-2 border-yellow-500' : ''}`}>*</button>
          <button onClick={() => performOperation('/')} className={`bg-primary hover:bg-primary-dark text-white font-semibold py-2 px-4 rounded-md transition-colors duration-150 ${lastButtonPressed === '/' ? 'border-2 border-yellow-500' : ''}`}>/</button>
          <button onClick={() => performOperation('=')} className={`bg-accent hover:bg-accent-dark text-white font-semibold py-2 px-4 rounded-md col-span-2 transition-colors duration-150 ${lastButtonPressed === '=' ? 'border-2 border-yellow-500' : ''}`}>=</button>
        </div>
      </div>
    </div>
  );
};

export default SimpleCalculator;