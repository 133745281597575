import React from 'react';
import { Helmet } from 'react-helmet';

const SupportUs = () => {
  return (
    <div className="max-w-3xl mx-auto my-8 p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
      <Helmet>
        <title>Support Us - Multitoolapp.com</title>
        <meta
          name="description"
          content="Support Multitoolapp.com by making a contribution. Your support helps us maintain and improve the website."
        />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200">Support Us</h2>
      <p className="leading-relaxed text-gray-700 dark:text-gray-300 mb-4">
        If you find our tools helpful and would like to support us, you can do so by making a contribution. Your support helps us maintain and improve the website. Thank you!
      </p>
      <div className="flex flex-col items-center">
        <a href="https://buymeacoffee.com/multitoolapp" target="_blank" rel="noopener noreferrer" className="my-2 py-2 px-4 rounded bg-green-500 text-white cursor-pointer hover:bg-green-600 transition-all duration-300 ease-in-out">
          Buy Me a Coffee
        </a>
      </div>
    </div>
  );
};

export default SupportUs;