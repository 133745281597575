import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { Square, CheckCircle, XCircle, Loader, Clock, Hash, Sparkles } from 'lucide-react';
import CopyButton from './CopyButton';
import AudioVisualizer from './AudioVisualizer';

const API_GATEWAY_URL = 'https://qugxfnno8e.execute-api.us-east-1.amazonaws.com';

const SpeechToText = () => {
  const [record, setRecord] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [status, setStatus] = useState('');
  const [transcribing, setTranscribing] = useState(false);
  const [history, setHistory] = useState([]);
  const [currentDuration, setCurrentDuration] = useState(0);
  const [enhancing, setEnhancing] = useState(false);
  const timerRef = useRef(null);
  const recordingStartTimeRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunksRef.current.push(e.data);
        }
      };
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: 'audio/wav' });
        onStop({ blob });
        chunksRef.current = [];
      };
      mediaRecorderRef.current.start();
      setRecord(true);
      setStatus('');
      recordingStartTimeRef.current = Date.now();
      timerRef.current = setInterval(() => {
        setCurrentDuration(Math.floor((Date.now() - recordingStartTimeRef.current) / 1000));
      }, 1000);
    } catch (err) {
      console.error("Error accessing microphone:", err);
      setStatus('Error accessing microphone');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
    setRecord(false);
    clearInterval(timerRef.current);
    setTranscribing(true);
  };

  const onStop = async (recordedBlob) => {
    console.log('recordedBlob is: ', recordedBlob);
    const finalDuration = Math.floor((Date.now() - recordingStartTimeRef.current) / 1000);

    try {
      const reader = new FileReader();
      reader.readAsDataURL(recordedBlob.blob);
      reader.onloadend = async () => {
        const base64Audio = reader.result;
        const response = await axios.post(`${API_GATEWAY_URL}/transcribe`, 
          { audio: base64Audio },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const newTranscript = response.data.text;
        setStatus('Transcription successful!');

        const newEntry = {
          text: newTranscript,
          timestamp: new Date().toLocaleString(),
          duration: formatTime(finalDuration),
          wordCount: newTranscript.split(' ').length,
        };
        setHistory(prevHistory => [newEntry, ...prevHistory]);
        setTranscribing(false);
        setTranscript('');
        setCurrentDuration(0);
      };
    } catch (error) {
      setStatus('Error transcribing audio');
      console.error('Error transcribing audio', error);
      setTranscribing(false);
      setCurrentDuration(0);
    }
  };

  const enhanceText = async (index) => {
    if (enhancing) return;
    setEnhancing(true);
    const textToEnhance = history[index].text;
    try {
      const response = await axios.post(
        `${API_GATEWAY_URL}/enhance`,
        { text: textToEnhance },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      
      const enhancedText = response.data.text;;
      
      const updatedHistory = [...history];
      updatedHistory[index] = {
        ...updatedHistory[index],
        enhancedText: enhancedText,
        enhancedWordCount: enhancedText.split(' ').length
      };
      setHistory(updatedHistory);
      setStatus('Text enhanced successfully!');
    } catch (error) {
      console.error('Error enhancing text:', error);
      setStatus('Error enhancing text');
    } finally {
      setEnhancing(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current);
      if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
        mediaRecorderRef.current.stop();
      }
    };
  }, []);

  useEffect(() => {
    const textareas = document.querySelectorAll('.auto-resize-textarea');
    textareas.forEach(textarea => {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    });
  }, [history]);

  return (
    <div className="max-w-3xl mx-auto bg-white dark:bg-gray-900 p-6 rounded-lg shadow-md">
      <Helmet>
        <title>Speech to Text - Multitoolapp.com</title>
        <meta name="description" content="Transcribe your speech to text using the Speech to Text tool with OpenAI Whisper API." />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200 text-center">Speech to Text</h2>
      <div className="mb-4 text-gray-700 dark:text-gray-300">
        <p>Convert your speech to text, and optionally enhance the text's readability.</p>
        <p className="text-sm mt-2">To use: Click 'Start Recording' and speak. Click the stop button to end recording. Your speech will be transcribed automatically. Use the blue buttons to improve the transcription's readability. Limit of 4 minutes.</p>
      </div>
      <div className="mb-4">
        <AudioVisualizer isRecording={record} />
      </div>
      {record && <div className="text-center mb-2 text-xl">{formatTime(currentDuration)}</div>}
      <div className="flex justify-center w-full">
        {!record ? (
          <button
            onClick={startRecording}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Start Recording
          </button>
        ) : (
          <button
            onClick={stopRecording}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
          >
            <Square size={20} />
          </button>
        )}
      </div>
      {transcribing && (
        <div className="mt-2 text-center text-sm flex justify-center">
          <Loader className="animate-spin" size={24} />
        </div>
      )}
      {status && (
        <div className="mt-2 text-center text-sm flex justify-center items-center">
          {status === 'Transcription successful!' || status === 'Text enhanced successfully!' ? (
            <CheckCircle className="text-green-500" size={20} />
          ) : (status === 'Error transcribing audio' || status === 'Error enhancing text') ? (
            <XCircle className="text-red-500" size={20} />
          ) : (
            status
          )}
          <span className="ml-2">{status}</span>
        </div>
      )}
      <h3 className="text-lg font-semibold mt-6">History</h3>
      <div className="space-y-4 mt-4">
        {history.map((entry, index) => (
          <div key={index} className="p-4 bg-gray-200 dark:bg-gray-700 rounded-lg shadow-sm">
            <div className="flex justify-between items-center mb-2">
              <div className="text-sm text-gray-600 dark:text-gray-400 flex items-center space-x-2">
                <span>{entry.timestamp}</span>
                <Clock size={16} />
                <span>{entry.duration}</span>
                <Hash size={16} />
                <span>{entry.wordCount} words</span>
                {entry.enhancedWordCount && (
                  <div className="flex items-center space-x-1 ml-2">
                    <Sparkles size={16} className="text-gray-600 dark:text-gray-400" />
                    <span>{entry.enhancedWordCount} words</span>
                  </div>
                )}
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => enhanceText(index)}
                  disabled={enhancing}
                  className="flex items-center justify-center p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  title="AI Enhance"
                >
                  <Sparkles size={16} />
                </button>
                <CopyButton textToCopy={entry.text} />
              </div>
            </div>
            <textarea
              value={entry.text}
              readOnly
              className="w-full p-2 border rounded-md resize-none overflow-hidden auto-resize-textarea bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200 mb-2"
              style={{ minHeight: '60px' }}
            />
            {entry.enhancedText && (
              <div className="mt-2">
                <div className="flex justify-between items-center mb-1">
                  <h4 className="text-sm font-semibold">Enhanced Text:</h4>
                  <CopyButton textToCopy={entry.enhancedText} />
                </div>
                <textarea
                  value={entry.enhancedText}
                  readOnly
                  className="w-full p-2 border rounded-md resize-none overflow-hidden auto-resize-textarea bg-green-100 dark:bg-green-900 text-gray-900 dark:text-gray-200"
                  style={{ minHeight: '60px' }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpeechToText;