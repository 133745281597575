import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Copy, Check } from 'lucide-react';

const ColorPicker = () => {
  const [color, setColor] = useState('#000000');
  const [rgb, setRgb] = useState({ r: 0, g: 0, b: 0 });
  const [hsl, setHsl] = useState({ h: 0, s: 0, l: 0 });
  const [activeInput, setActiveInput] = useState('hex');

  useEffect(() => {
    updateColorValues(color);
  }, [color]);

  const updateColorValues = (hexColor) => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    setRgb({ r, g, b });

    const rgbToHsl = (r, g, b) => {
      r /= 255; g /= 255; b /= 255;
      const max = Math.max(r, g, b), min = Math.min(r, g, b);
      let h, s, l = (max + min) / 2;

      if (max === min) {
        h = s = 0;
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r: h = (g - b) / d + (g < b ? 6 : 0); break;
          case g: h = (b - r) / d + 2; break;
          case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
      }

      return { h: Math.round(h * 360), s: Math.round(s * 100), l: Math.round(l * 100) };
    };

    setHsl(rgbToHsl(r, g, b));
  };

  const handleHexChange = (e) => {
    const newColor = e.target.value;
    if (/^#[0-9A-F]{6}$/i.test(newColor)) {
      setColor(newColor);
    }
  };

  const handleRgbChange = (channel, value) => {
    const newRgb = { ...rgb, [channel]: Math.min(255, Math.max(0, parseInt(value) || 0)) };
    setRgb(newRgb);
    setColor(`#${newRgb.r.toString(16).padStart(2, '0')}${newRgb.g.toString(16).padStart(2, '0')}${newRgb.b.toString(16).padStart(2, '0')}`);
  };

  const handleHslChange = (channel, value) => {
    const newHsl = { ...hsl, [channel]: channel === 'h' ? Math.min(360, Math.max(0, parseInt(value) || 0)) : Math.min(100, Math.max(0, parseInt(value) || 0)) };
    setHsl(newHsl);
    // Convert HSL to RGB, then to HEX
    const hslToRgb = (h, s, l) => {
      s /= 100; l /= 100;
      const k = n => (n + h / 30) % 12;
      const a = s * Math.min(l, 1 - l);
      const f = n => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
      return [255 * f(0), 255 * f(8), 255 * f(4)].map(Math.round);
    };
    const [r, g, b] = hslToRgb(newHsl.h, newHsl.s, newHsl.l);
    setColor(`#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`);
  };

  const CopyButton = ({ textToCopy }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
      navigator.clipboard.writeText(textToCopy).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    };

    return (
      <button
        onClick={handleCopy}
        className="ml-2 p-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {copied ? <Check size={20} /> : <Copy size={20} />}
      </button>
    );
  };

  return (
    <div className="max-w-md mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <Helmet>
        <title>Color Picker - Multitoolapp.com</title>
        <meta
          name="description"
          content="Use our Color Picker tool to select colors and get their HEX, RGB, and HSL values. Perfect for designers and developers."
        />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200">Color Picker</h2>
      <div className="mb-4 text-gray-700 dark:text-gray-300">
        <p>Select and explore different colors with our intuitive color picker.</p>
        <p className="text-sm mt-2">To use: Click on the color box to choose a color, or input values directly in HEX, RGB, or HSL formats. Use the copy button to easily share your selected color.</p>
      </div>
      
      <div className="mb-4 relative">
        <div 
          className="w-full h-40 rounded-md shadow-sm cursor-pointer"
          style={{ backgroundColor: color }}
          onClick={() => document.getElementById('color-input').click()}
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="bg-white dark:bg-gray-700 px-2 py-1 rounded text-sm font-medium text-gray-700 dark:text-gray-200">
              Click to pick a color
            </span>
          </div>
        </div>
        <input
          id="color-input"
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        />
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">HEX</label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              value={color}
              onChange={handleHexChange}
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-md border-gray-300 dark:border-gray-600 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              placeholder="#000000"
            />
            <CopyButton textToCopy={color} />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">RGB</label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="number"
              value={rgb.r}
              onChange={(e) => handleRgbChange('r', e.target.value)}
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-l-md border-r-0 border-gray-300 dark:border-gray-600 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              placeholder="R"
              min="0"
              max="255"
            />
            <input
              type="number"
              value={rgb.g}
              onChange={(e) => handleRgbChange('g', e.target.value)}
              className="flex-1 min-w-0 block w-full px-3 py-2 border-r-0 border-gray-300 dark:border-gray-600 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              placeholder="G"
              min="0"
              max="255"
            />
            <input
              type="number"
              value={rgb.b}
              onChange={(e) => handleRgbChange('b', e.target.value)}
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-r-md border-gray-300 dark:border-gray-600 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              placeholder="B"
              min="0"
              max="255"
            />
            <CopyButton textToCopy={`rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`} />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">HSL</label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="number"
              value={hsl.h}
              onChange={(e) => handleHslChange('h', e.target.value)}
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-l-md border-r-0 border-gray-300 dark:border-gray-600 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              placeholder="H"
              min="0"
              max="360"
            />
            <input
              type="number"
              value={hsl.s}
              onChange={(e) => handleHslChange('s', e.target.value)}
              className="flex-1 min-w-0 block w-full px-3 py-2 border-r-0 border-gray-300 dark:border-gray-600 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              placeholder="S"
              min="0"
              max="100"
            />
            <input
              type="number"
              value={hsl.l}
              onChange={(e) => handleHslChange('l', e.target.value)}
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-r-md border-gray-300 dark:border-gray-600 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              placeholder="L"
              min="0"
              max="100"
            />
            <CopyButton textToCopy={`hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;