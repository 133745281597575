import React from 'react';
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <div className="bg-white dark:bg-gray-800 overflow-hidden shadow-xl sm:rounded-lg p-6">
      <Helmet>
        <title>About Us - Multitoolapp</title>
        <meta
          name="description"
          content="Welcome to Multitoolapp.com, where innovation meets functionality. Our mission is to enhance your experience with beautifully designed, functional tools that bring joy and efficiency to your daily tasks."
        />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200">About</h2>
      <p className="text-gray-700 dark:text-gray-300">
        Welcome to Multitoolapp.com, where innovation meets functionality. Our mission is to enhance your experience with beautifully designed, functional tools that not only serve a purpose but also bring joy and efficiency to your daily tasks. We strive to create products that simplify complex processes, making your life easier and more productive.
      </p>
      <p className="mt-4 text-gray-700 dark:text-gray-300">
        We believe in the power of simplicity and efficiency. Our team of dedicated professionals works tirelessly to develop tools that are intuitive, reliable, and effective. From countdown timers to password generators, each of our utilities is crafted with attention to detail and a deep understanding of user needs.
      </p>
      <p className="mt-4 text-gray-700 dark:text-gray-300">
        Our journey began with a simple idea: to create a suite of tools that cater to a wide range of tasks, all in one place. Over the years, we have expanded our offerings, incorporating user feedback and technological advancements to ensure our tools remain relevant and useful.
      </p>
      <p className="mt-4 text-gray-700 dark:text-gray-300">
        <strong>Our Values:</strong>
      </p>
      <ul className="list-disc list-inside pl-4 mt-2 text-gray-700 dark:text-gray-300">
        <li><strong>Innovation:</strong> We constantly seek new ways to improve and expand our offerings.</li>
        <li><strong>Quality:</strong> Our products are built to the highest standards of reliability and performance.</li>
        <li><strong>User-Centricity:</strong> Your needs and feedback drive our development process.</li>
        <li><strong>Simplicity:</strong> We design our tools to be intuitive and easy to use.</li>
      </ul>
      <p className="mt-4 text-gray-700 dark:text-gray-300">
        <strong>Contact Us:</strong>
      </p>
      <p className="mt-2 text-gray-700 dark:text-gray-300">
        Have questions, suggestions, or feedback? Reach out to us at <a href="mailto:multitoolapp0@gmail.com" className="text-primary dark:text-primary hover:underline">multitoolapp0@gmail.com</a>. 
      </p>
      <p className="mt-4 text-gray-700 dark:text-gray-300">
        We are here to assist you and ensure you have the best possible experience with our products.
      </p>
    </div>
  );
};

export default About;