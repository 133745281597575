import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Copy, RefreshCw, Check, Shield, Info } from 'lucide-react';
import { Tooltip } from 'react-tooltip';

const PasswordGenerator = () => {
  const [length, setLength] = useState(12);
  const [includeUppercase, setIncludeUppercase] = useState(true);
  const [includeLowercase, setIncludeLowercase] = useState(true);
  const [includeNumbers, setIncludeNumbers] = useState(true);
  const [includeSpecialChars, setIncludeSpecialChars] = useState(true);
  const [password, setPassword] = useState('');
  const [copied, setCopied] = useState(false);
  const [timeToCrack, setTimeToCrack] = useState('');
  const [timeToCrackColor, setTimeToCrackColor] = useState('text-red-500');

  useEffect(() => {
    if (password) {
      calculateTimeToCrack();
    }
  }, [password]);

  const generatePassword = () => {
    let charset = '';
    if (includeUppercase) charset += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (includeLowercase) charset += 'abcdefghijklmnopqrstuvwxyz';
    if (includeNumbers) charset += '0123456789';
    if (includeSpecialChars) charset += '!@#$%^&*()_+-=[]{}|;:,.<>?';

    let newPassword = '';
    for (let i = 0; i < length; i++) {
      newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    setPassword(newPassword);
    setCopied(false);
  };

  const calculateTimeToCrack = () => {
    const charsetSize = (includeUppercase ? 26 : 0) +
                        (includeLowercase ? 26 : 0) +
                        (includeNumbers ? 10 : 0) +
                        (includeSpecialChars ? 33 : 0);
    
    const combinations = Math.pow(charsetSize, length);
    const guessesPerSecond = 1000000000; // Assume 1 billion guesses per second
    const seconds = combinations / guessesPerSecond;

    if (seconds < 60) {
      setTimeToCrack('Instant');
      setTimeToCrackColor('text-red-500');
    } else if (seconds < 3600) {
      const minutes = Math.round(seconds / 60);
      setTimeToCrack(`${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`);
      setTimeToCrackColor('text-red-500');
    } else if (seconds < 86400) {
      const hours = Math.round(seconds / 3600);
      setTimeToCrack(`${hours} ${hours === 1 ? 'hour' : 'hours'}`);
      setTimeToCrackColor('text-yellow-500');
    } else if (seconds < 31536000) {
      const days = Math.round(seconds / 86400);
      setTimeToCrack(`${days} ${days === 1 ? 'day' : 'days'}`);
      setTimeToCrackColor('text-yellow-500');
    } else if (seconds < 3153600000) {
      const years = Math.round(seconds / 31536000);
      setTimeToCrack(`${years} ${years === 1 ? 'year' : 'years'}`);
      setTimeToCrackColor('text-green-500');
    } else {
      setTimeToCrack('Centuries');
      setTimeToCrackColor('text-green-500');
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(password).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const ToggleSwitch = ({ checked, onChange, label }) => (
    <label className="flex items-center space-x-3 cursor-pointer">
      <div className="relative">
        <input type="checkbox" className="sr-only" checked={checked} onChange={onChange} />
        <div className={`w-10 h-6 bg-gray-300 rounded-full shadow-inner transition-colors duration-300 ease-in-out ${checked ? 'bg-green-400' : ''}`}></div>
        <div className={`absolute w-4 h-4 bg-white rounded-full shadow top-1 left-1 transition-transform duration-300 ease-in-out ${checked ? 'transform translate-x-full' : ''}`}></div>
      </div>
      <span className="text-sm font-medium text-white">{label}</span>
    </label>
  );

  return (
    <div className="max-w-md mx-auto bg-gradient-to-br from-purple-600 to-indigo-700 p-8 rounded-2xl shadow-lg">
      <Helmet>
        <title>Password Generator - Multitoolapp.com</title>
        <meta
          name="description"
          content="Generate secure and random passwords with our Password Generator tool. Customize length and character types."
        />
      </Helmet>
      <h2 className="text-3xl font-bold mb-6 text-white text-center">Password Generator</h2>
      
      <div className="mb-6">
        <label className="block text-white mb-2">Password Length: {length}</label>
        <input
          type="range"
          min="4"
          max="30"
          value={length}
          onChange={(e) => setLength(parseInt(e.target.value))}
          className="w-full h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer"
        />
      </div>

      <div className="space-y-4 mb-6">
        <ToggleSwitch checked={includeUppercase} onChange={(e) => setIncludeUppercase(e.target.checked)} label="Include Uppercase" />
        <ToggleSwitch checked={includeLowercase} onChange={(e) => setIncludeLowercase(e.target.checked)} label="Include Lowercase" />
        <ToggleSwitch checked={includeNumbers} onChange={(e) => setIncludeNumbers(e.target.checked)} label="Include Numbers" />
        <ToggleSwitch checked={includeSpecialChars} onChange={(e) => setIncludeSpecialChars(e.target.checked)} label="Include Special Characters" />
      </div>

      <button
        onClick={generatePassword}
        className="w-full bg-white text-purple-700 font-bold py-2 px-4 rounded-lg hover:bg-gray-100 transition-colors duration-300 flex items-center justify-center"
      >
        <RefreshCw size={18} className="mr-2" />
        Generate Password
      </button>

      <div className="mt-6 relative">
        <input
          type="text"
          value={password}
          readOnly
          className="w-full px-4 py-3 text-gray-800 bg-white bg-opacity-90 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent transition-all duration-300"
        />
        <button
          onClick={copyToClipboard}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-purple-700 hover:text-purple-900 transition-colors duration-300"
        >
          {copied ? <Check size={20} /> : <Copy size={20} />}
        </button>
      </div>

      {password && (
        <div className="mt-4 flex items-center justify-between bg-white bg-opacity-20 p-3 rounded-lg">
          <div className="flex items-center">
            <Shield size={20} className={`mr-2 ${timeToCrackColor}`} />
            <span className={`text-white font-medium ${timeToCrackColor}`}>Time to crack:</span>
            <Info 
              size={16} 
              className="ml-2 text-white cursor-pointer"
              data-tooltip-id="info-tooltip"
              data-tooltip-content="Calculated assuming 1 billion guesses per second"
            />
            <Tooltip id="info-tooltip" place="top" type="dark" effect="solid" />
          </div>
          <span className={`text-white font-bold ${timeToCrackColor}`}>{timeToCrack}</span>
        </div>
      )}
    </div>
  );
};

export default PasswordGenerator;